<template>
  <!-- Main Content -->
  <div class="siteList" style="list-style-type: none">
    <!-- Sidebar  -->
    <sidebar></sidebar>
    <div class="content-center">
      <div class="header" style="font-size: 30px">Site List</div>
      <div class="list-nav">
        <div class="left-buttons">
          <Search />
          <b-dropdown
            text="Filter Sites"
            block
            variant="outline-dark"
            class="filter-dropdown"
          >
            <b-dropdown-item-button
              key="all"
              @click.prevent="filterByActive = false"
            >
              All Sites
            </b-dropdown-item-button>
            <b-dropdown-item-button
              key="active"
              @click.prevent="filterByActive = true"
            >
              Active Sites
            </b-dropdown-item-button>
          </b-dropdown>
          <button @click="exportAsCSV()" class="export-btn btn btn-primary">
            Export as CSV
          </button>
        </div>
        <Pagination />
      </div>
      <!-- Grid  -->
      <grid
        @refetch="refetchData()"
        :filterByActive="filterByActive"
        v-if="status === 'idle'"
        ref="grid"
      ></grid>
      <div v-if="status === 'loading'">Loading...</div>
    </div>
  </div>
</template>

<!-- Script for styling  -->
<script
  src="https://cdn.jsdelivr.net/npm/bootstrap@5.0.0-beta2/dist/js/bootstrap.bundle.min.js"
  integrity="sha384-b5kHyXgcpbZJO/tY9Ul7kGkf1S0CWuKcCD38l8YkeH8z8QjE0GmW1gYU5S9FOnJ0"
  crossorigin="anonymous"
></script>

<script>
import Sidebar from "../components/Sidebar.vue";
import Grid from "../components/Grid.vue";
import Pagination from "../components/Pagination.vue";
import Search from "../components/Search.vue";

export default {
  components: {
    Sidebar,
    Grid,
    Pagination,
    Search,
  },
  name: "Home",
  data: function () {
    return {
      filterByActive: false,
    };
  },
  computed: {
    status() {
      return this.$store.getters.getSites &&
        this.$store.getters.getClientCollectionIDs
        ? "idle"
        : "loading";
    },
  },
  methods: {
    refetchData() {
      this.$parent.getData();
    },
    exportAsCSV() {
      this.$refs.grid.exportTableAsCSV();
    },
  },
  mounted() {
    this.refetchData();
  },
};
</script>

<style scoped>
@import "https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700";

body {
  font-family: "Poppins", sans-serif;
  background: #fafafa;
}

p {
  font-family: "Poppins", sans-serif;
  font-size: 1.1em;
  font-weight: 300;
  line-height: 1.7em;
  color: #999;
}

a,
a:hover,
a:focus {
  color: inherit;
  text-decoration: none;
  transition: all 0.3s;
}
.content-left {
  flex: 1;
}

.active {
  background-color: #c0c0c0;
}
.active.dropdown-item {
  color: white !important;
}
.line {
  width: 100%;
  height: 1px;
  border-bottom: 1px dashed #ddd;
  margin: 40px 0;
}
.siteList {
  display: flex;
}

.header {
  height: 30px;
  margin-bottom: 40px;
  margin-top: 20px;
  font-size: 120%;
}
.list-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 10px;
}
.left-buttons {
  display: flex;
  flex-direction: row;
  align-items: center;
}

/* ---------------------------------------------------
    CONTENT STYLE
----------------------------------------------------- */
#content {
  width: calc(100% - 250px);
  padding: 40px;
  min-height: 100vh;
  transition: all 0.3s;
  position: absolute;
  top: 0;
  right: 0;
}
#content.active {
  width: 100%;
}
.content-center {
  width: 100%;
}
.dropdown,
.export-btn {
  margin-left: 10px;
}
/* ---------------------------------------------------
    MEDIAQUERIES
----------------------------------------------------- */
@media only screen and (max-width: 550px) {
  #sidebar {
    margin-left: -250px;
  }
}
</style>
