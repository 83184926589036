import axios from "axios";
import { getInstance } from "../auth/auth-plugin";

const ApiService = {
  _URL: null,
  init(baseURL) {
    axios.defaults.baseURL = baseURL;
    this._URL = baseURL;
    axios.defaults.xsrfCookieName = "csrftoken";
    axios.defaults.xsrfHeaderName = "X-CSRFToken";
  },
  setHeader(token) {
    axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  },

  getAuthorizationHeader() {
    return axios.defaults.headers.common["Authorization"];
  },

  removeHeader() {
    axios.defaults.headers.common = {};
  },

  get(resource, data) {
    return axios.get(resource, data);
  },

  post(resource, data) {
    return axios.post(resource, data);
  },

  put(resource, data) {
    return axios.put(resource, data);
  },

  patch(resource, data) {
    return axios.patch(resource, data);
  },

  delete(resource) {
    return axios.delete(resource);
  },

  customRequest(data) {
    return axios(data);
  },

  _409interceptor: null,

  mount409Interceptor() {
    this._409interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response.status == 409) {
          return error.response;
        } else {
          return Promise.reject(error);
        }
      }
    );
  },
  _401interceptor: null,

  mount401Interceptor() {
    this._401interceptor = axios.interceptors.response.use(
      (response) => {
        return response;
      },
      async (error) => {
        console.log(error);
        if (error.response.status == 401) {
          alert("User does not have permissions to access this site");
          getInstance().logout();
          return Promise.reject(error);
        } else {
          return Promise.reject(error);
        }
      }
    );
  },
  unmount401Interceptor() {
    axios.interceptors.response.eject(this._401interceptor);
  },
};

export default ApiService;
