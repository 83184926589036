import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import { authenticationGuard } from "../auth/authenticationGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
    beforeEnter: authenticationGuard
  },
  {
    path: "/login",
    name: "Login",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Login.vue"),
  },
  {
    path: "/create",
    name: "Create",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CreateSite.vue"),
    beforeEnter: authenticationGuard
  },
  {
    path: "/createClientData",
    name: "CreateClientData",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/CreateClientData.vue"),
    beforeEnter: authenticationGuard,
    meta: {
      adminOnly: true
    }
  },
  {
    path: "/duplicateClient",
    name: "DuplicateClient",
    component: () => import("../views/DuplicateClient.vue"),
    beforeEnter: authenticationGuard,
    meta: {
      adminOnly: true
    }
  },
  {
    path: "/activateSite/:siteID",
    name: "ActivateSite",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/ActivateSite.vue"),
    beforeEnter: authenticationGuard,
    meta: {
      adminOnly: true
    }
  },
  {
    path: "/addUser",
    name: "AddUser",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AddUser.vue"),
    beforeEnter: authenticationGuard,
    meta: {
      adminOnly: true
    }
  },
  {
    path: "/scrapeClientCollection",
    name: "ScrapeClientCollections",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/Scraping.vue"),
    beforeEnter: authenticationGuard,
    meta: {
      adminOnly: true
    }
  },
  {
    path: "/emailList",
    name: "EmailList",
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/EmailList.vue"),
    beforeEnter: authenticationGuard,
    meta: {
      adminOnly: true
    }
  },
  {
    path: "/emailUtils",
    name: "EmailUtils",
    component: () =>
      import(/* webpackChunkName: "emailUtils" */ "../views/EmailUtils.vue"),
    beforeEnter: authenticationGuard,
    meta: {
      adminOnly: true
    }
  },
  {
    path: "/copyCategory",
    name: "CopyCategory",
    component: () =>
      import(/* webpackChunkName: "copyCategory" */ "../views/CopyCategory.vue"),
    beforeEnter: authenticationGuard,
    meta: {
      adminOnly: true
    }
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
