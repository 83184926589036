import Vue from "vue";
import createAuth0Client from "@auth0/auth0-spa-js";
import ApiService from "../services/api.service";

let instance;

export const getInstance = () => instance;

export const useAuth0 = ({
  onRedirectCallback = () =>
    window.history.replaceState({}, document.title, window.location.pathname),
  redirectUri = window.location.origin,
  ...pluginOptions
}) => {
  if (instance) {
    return instance;
  }

  instance = new Vue({
    data() {
      return {
        auth0Client: null,
        isLoading: true,
        isAuthenticated: false,
        user: {},
        error: null,
        isSiteAdmin: false,
      };
    },
    methods: {
      // handle redirect callback from universal login page
      async handleRedirectCallback() {
        this.isLoading = true;
        try {
          await this.auth0Client.handleRedirectCallback();
          this.user = await this.auth0Client.getUser();
          this.isAuthenticated = true;
        } catch (error) {
          this.error = error;
          alert("Error Logging In: " + error);
        } finally {
          this.isLoading = false;
        }
      },
      // trigger login to universal login page
      loginWithRedirect(options) {
        return this.auth0Client.loginWithRedirect(options);
      },

      logout(options) {
        return this.auth0Client.logout(options);
      },

      getTokenSilently(options) {
        return this.auth0Client.getTokenSilently(options);
      },
      getIsSiteAdmin(){
        return this.isSiteAdmin;
      },
    },
    async created() {
      try {
        this.auth0Client = await createAuth0Client({
          ...pluginOptions,
          domain: pluginOptions.domain,
          client_id: pluginOptions.clientId,
          redirect_uri: redirectUri,
          audience: process.env.VUE_APP_AUTH0_AUDIENCE,
        });
        if (
          window.location.search.includes("code=") &&
          window.location.search.includes("state=")
        ) {
          const { appState } = await this.auth0Client.handleRedirectCallback();
          onRedirectCallback(appState);
        }
        this.user = await this.auth0Client.getUser();
        this.isAuthenticated = await this.auth0Client.isAuthenticated();
        if (this.isAuthenticated) {
          const token = await this.getTokenSilently();
          ApiService.setHeader(token);
          // check if users has permissions to access dashboard
          // it not allowed will return 401 which is caught by axios interceptor and causes logout
          const response = await ApiService.post("authorize/dashboard/");
          if (response.data.isSiteAdmin) {
            this.isSiteAdmin = true;
          }
        }
        this.isLoading = false;
      } catch (error) {
        this.error = error;
        alert(error);
      }
    },
  });

  return instance;
};

export const Auth0Plugin = {
  install(Vue, options) {
    Vue.prototype.$auth = useAuth0(options);
  },
};
