import { getInstance } from "./auth-plugin";
export const authenticationGuard = (to, from, next) => {
  const authService = getInstance();

  if (to.Name === "Login") {
    return next();
  }

  const guardAction = () => {
    if (authService.isAuthenticated) {
      if (to.meta.adminOnly && !authService.isSiteAdmin) {
        return next({ name: "Home" })
      }
      return next();
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } });
  };

  // If the Auth0Plugin has loaded already, check the authentication state
  if (!authService.isLoading) {
    return guardAction();
  }

  authService.$watch("isLoading", (isLoading) => {
    if (isLoading === false) {
      return guardAction();
    }
  });
};
