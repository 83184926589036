import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    clientCollectionIDs: undefined,
    sites: undefined,
    siteNames: undefined,
    currPage: 0, // pagination
    search: "",
  },
  mutations: {
    setUserID(state, payload) {
      state.userID = payload;
    },
    setClientCollectionIDs(state, payload) {
      state.clientCollectionIDs = payload;
    },
    setSites(state, payload) {
      state.sites = payload;
    },
    setSiteNames(state, payload) {
      state.siteNames = payload;
    },
    setCurrPage(state, payload) {
      state.currPage = payload;
    },
    setSearch(state, payload) {
      state.search = payload;
    },
  },
  actions: {
    logout() {
      this.$auth.logout({});
    },
    setClientCollectionIDs({ commit }, payload) {
      commit("setClientCollectionIDs", payload);
    },
    setSites({ commit }, payload) {
      commit("setSites", payload);
    },
    setSiteNames({ commit }, payload) {
      commit("setSiteNames", payload);
    },
    setCurrPage({ commit }, payload) {
      commit("setCurrPage", payload);
    },
    setSearch({ commit }, payload) {
      commit("setSearch", payload);
    },
  },
  modules: {},
  getters: {
    getUserID: (state) => state.userID,
    getClientCollectionIDs: (state) => state.clientCollectionIDs,
    getSites: (state) => state.sites,
    getSiteNames: (state) => state.siteNames,
    getCurrPage: (state) => state.currPage,
    getSearch: (state) => state.search,
  },
});
