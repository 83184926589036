export default {
  methods: {
    /**
     * Updates a siteID's info (such as name or data it is attached to) and then re-fetches sites from database
     *
     * @param {Number} siteID the id of the site we want to edit
     * @param {String} attributeLabel the front-facing name of the attribute we will edit (for success/error messages)
     * @param {Object} attributes an object mapping attribute name to value (ie. { styleName: 'New Style Name' })
     */
    updateSiteInfo(siteID, attributeLabel, attributes) {
      this.$api
        .patch(`/siteID/${siteID}/`, attributes)
        .then(() => {
          alert(
            `The ${attributeLabel} for site ${siteID} has been successfully changed`
          );
          this.$emit("refetch");
        })
        .catch(() => {
          alert(
            `Error occurred while updating site ${attributeLabel}. Please contact support.`
          );
        });
    },

    updateCsDetails(siteID, attributeLabel, csDetails) {

      const requiredFields = ['csAlert', 'siteOwner', 'installationMedia', 'notes'];

      // Check if all required fields are present in csDetails
      const missingFields = requiredFields.filter(field => !(field in csDetails));

      if (missingFields.length > 0) {
        // Alert the missing fields and return early
        alert(`Error: Missing fields in csDetails: ${missingFields.join(', ')}. Update aborted.`);
        console.error(`updateCsDetails called with incomplete csDetails for siteID ${siteID}. Missing fields: ${missingFields.join(', ')}.`);
        return;
      }
      // if csDetails.installationMedia is an array of objects, map it to an array of the  _ids
      const sanitizedCsDetails = { ...csDetails };
      if (csDetails.installationMedia.length) {
        sanitizedCsDetails.installationMedia = csDetails.installationMedia.map(
          (media) => media._id ? media._id : media
        );
      }
      return this.updateSiteInfo(siteID, attributeLabel, { csDetails: sanitizedCsDetails });
    }
  }
};
