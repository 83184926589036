<template>
  <div
    id="app"
    v-if="this.$auth.isAuthenticated || this.$route.name == 'Login'"
  >
    <router-view />
  </div>
</template>
<script>
export default {
  methods: {
    getData() {
      /* Request to get list of all sites (styles) */
      this.$api.get(`/userSiteIDMetadata`).then((res) => {
        const clientStyles = res.data;
        const siteNames = clientStyles.map(({ styleName }) => styleName);
        this.$store.dispatch("setSiteNames", siteNames); // for search
        this.$store.dispatch("setSites", clientStyles);
      });
      /* Request to get object of client collection ids to client collection names */
      this.$api.get(`/userCollections`).then(({ data }) => {
        const mostCommonDataSites = ["Lehigh University Old"];
        const sortedData = [
          ...data.filter(({ name }) => mostCommonDataSites.includes(name)),
          ...data.filter(({ name }) => !mostCommonDataSites.includes(name)),
        ];
        this.$store.dispatch("setClientCollectionIDs", sortedData);
      }).catch;
    },
  },
  mounted() {
    if (this.$auth.isAuthenticated) {
      this.getData();
    }
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  width: 100vw;
  height: 100vh;
}

#nav {
  padding: 30px;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
