import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import ApiService from "./services/api.service";
import { Auth0Plugin } from "./auth/auth-plugin";

Vue.config.productionTip = false;
ApiService.init(process.env.VUE_APP_API_URL);

ApiService.mount401Interceptor();
ApiService.mount409Interceptor();

Vue.prototype.$api = ApiService;
Vue.use(Auth0Plugin, {
  domain: process.env.VUE_APP_AUTH0_DOMAIN,
  clientId: process.env.VUE_APP_AUTH0_CLIENT_ID,
  redirectUri: window.location.origin + "/",
  onRedirectCallback: (appState) => {
    let destination = "/";
    if (appState != undefined && appState.targetUrl) {
      destination = appState.targetUrl;
    }
    router.push(destination);
  },
});

import {
  DropdownPlugin,
  ButtonPlugin,
  FormInputPlugin,
  FormPlugin,
  FormSelectPlugin,
  FormGroupPlugin,
  FormFilePlugin,
  FormCheckboxPlugin,
  CardPlugin,
  FormTextareaPlugin,
  BIconChevronRight,
  BIconChevronLeft,
} from "bootstrap-vue";
Vue.use(DropdownPlugin, { lazy: true });
Vue.use(ButtonPlugin);
Vue.use(FormInputPlugin);
Vue.use(FormPlugin);
Vue.use(FormSelectPlugin);
Vue.use(FormGroupPlugin);
Vue.use(FormFilePlugin);
Vue.use(FormCheckboxPlugin);
Vue.use(CardPlugin);
Vue.use(FormTextareaPlugin);
Vue.component("BIconChevronLeft", BIconChevronLeft);
Vue.component("BIconChevronRight", BIconChevronRight);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");
