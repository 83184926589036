<template>
  <div class="site-rating-details">
    <div v-for="(issue, key) in breakdown" :key="key" class="detail">
      <span> {{ issue.label }}:</span><span> {{ issue.value }}</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    breakdown: {
      type: Object,
      required: true,
    },
  },
};
</script>

<style scoped>
.site-rating-details {
  display: flex;
  flex-direction: column;
  gap: 5px;
  font-size: 10px;
}
.detail {
  display: flex;
  justify-content: space-between;
}
</style>