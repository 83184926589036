export default {
  methods: {
    formatTimestamp(siteIDMetadata, key) {
      if (key in siteIDMetadata && siteIDMetadata[key]) {
        let diff = (Date.now() - new Date(siteIDMetadata[key])) / (1000 * 60); // diff in minutes
        let unit = "minutes";
        if (diff / 60 > 1) {
          const diffHours = diff / 60;
          if (diffHours > 24) {
            diff = diffHours / 24;
            unit = "days";
          } else {
            diff = diff / 60;
            unit = "hours";
          }
        }
        const diffFloor = Math.floor(diff);
        return `${diffFloor} ${unit} ago`;
      }
      return "N/A";
    },
    formatLastTouchscreenHeartbeatTimestamp(site) {
      if (!site.lastTouchscreenHeartbeat) {
        return "N/A";
      }
      if (typeof site.lastTouchscreenHeartbeat === "object") {
        return `${this.formatTimestamp(
          site.lastTouchscreenHeartbeat,
          "timestamp"
        )}`;
      }
      return this.formatTimestamp(site, "lastTouchscreenHeartbeat");
    },
  },
};
