<template>
  <b-dropdown :text="text" block variant="transparent">
    <b-dropdown-item-button
      v-for="option in options"
      :key="option.key"
      @click.prevent="setSortingData(option.sortKey, option.order)"
    >
      {{ option.label }}
    </b-dropdown-item-button>
  </b-dropdown>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  methods: {
    setSortingData(sortKey, order) {
      this.$emit("setSortingData", sortKey, order);
    },
  },
};
</script>