export default {
  computed: {
    filteredSites() {
      const search = this.$store.getters.getSearch;
      let sites = this.$store.getters.getSites;
      if (this.filterByActive) {
        sites = (sites || []).filter(({ isActivated }) => isActivated);
      }
      return (sites || []).filter(({ styleName }) =>
        styleName.toLowerCase().includes(search.toLowerCase())
      );
    },
  },
};
