<template>
  <vue-dropzone
    class="dropzone"
    :id="`dropzone-${siteID}`"
    :ref="`dropzone-${siteID}`"
    :options="dropzoneOptions"
    @vdropzone-success="uploadMedia"
    @vdropzone-removed-file="removeFile"
    @vdropzone-mounted="manuallyAddFiles"
    :destroyDropzone="false"
  />
</template>

<script>
import vue2Dropzone from "vue2-dropzone";
import "vue2-dropzone/dist/vue2Dropzone.min.css";
import updateSiteInfoMixin from "@/mixins/updateSiteInfo.js";

export default {
  name: "InstallationMediaUpload",
  components: {
    vueDropzone: vue2Dropzone,
  },
  mixins: [updateSiteInfoMixin],
  props: {
    siteID: {
      type: String,
      required: true,
    },
    csDetails: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropzoneOptions: {
        url: `${this.$api._URL}/media/v2/siteID/dashboard`,
        thumbnailWidth: 50,
        thumbnailHeight: 50,
        addRemoveLinks: true,
        maxFiles: 1,
        dictDefaultMessage:
          "<i class='fa fa-cloud-upload'></i> UPLOAD: Installation Image ",
        thumbnailMethod: "contain",
        acceptedFiles: ".png, .gif, .jpeg, .jpg, .svg-xml, .ico, .webp",
        headers: {
          "Cache-Control": null,
          "X-Requested-With": null,
          Authorization: this.$api.getAuthorizationHeader(),
        },
      },
    };
  },
  methods: {
    uploadMedia(file, response) {
      const newMedia = [...this.csDetails.installationMedia, response._id];
      const newDetails = { ...this.csDetails, installationMedia: newMedia };
      this.updateCsDetails(this.siteID, "Installation Media", newDetails);
    },
    removeFile(file) {
      const newMedia = (this.csDetails.installationMedia || []).filter(
        (media) => media._id !== file._id
      );
      const newDetails = { ...this.csDetails, installationMedia: newMedia };
      this.updateCsDetails(this.siteID, "Installation Media", newDetails);
    },
    manuallyAddFiles() {
      const { installationMedia } = this.csDetails;
      if (!installationMedia) return;
      for (const media of this.csDetails.installationMedia) {
        const url = `https://media.rocketalumnisolutions.com/${media.uuid}/`;
        const file = {
          name: media.name,
          type: media.mimeType,
          size: media.size == null ? null : media.size,
          link: url,
          dataURL: url, // needed for thumbnail
          _id: media._id,
        };
        const dropzoneRef = this.$refs[`dropzone-${this.siteID}`];
        dropzoneRef.manuallyAddFile(file, url);
        // Emit events to generate the thumbnail
        dropzoneRef.dropzone.emit("thumbnail", file, url);
        dropzoneRef.dropzone.emit("complete", file);
      }
    },
  },
};
</script>

<style>
.dropzone {
  height: 100px !important;
  margin: 0;
}

.dropzone .dz-message {
  margin: 0;
}

.dropzone .dz-preview {
  margin: 0;
  width: 100px;
  height: 50px;
}

.dropzone .dz-preview .dz-image img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
</style>