<template>
  <div class="pagination-container" v-if="getSites !== undefined">
    <div style="padding: 8px">
      {{ !!filteredSites.length ? start : 0 }} - {{ end }} of
      {{ filteredSites.length }}
    </div>
    <button
      class="chevron-button"
      title="Previous Page"
      :disabled="getCurrPage === 0"
      @click="prevPage"
    >
      <b-icon-chevron-left />
    </button>
    <button
      class="chevron-button"
      title="Next Page"
      :disabled="onLastPage"
      @click="nextPage"
    >
      <b-icon-chevron-right />
    </button>
  </div>
</template>

<script>
import { PAGE_SIZE } from "@/utils/constants";
import filteredSitesMixin from "@/mixins/filteredSites";

export default {
  name: "Pagination",
  mixins: [filteredSitesMixin],
  computed: {
    getCurrPage() {
      return this.$store.getters.getCurrPage;
    },
    getSites() {
      return this.$store.getters.getSites;
    },
    onLastPage() {
      return this.filteredSites.length <= this.end;
    },
    start() {
      return this.getCurrPage * PAGE_SIZE + 1;
    },
    end() {
      return Math.min(
        (this.getCurrPage + 1) * PAGE_SIZE,
        this.filteredSites.length
      );
    },
  },
  methods: {
    nextPage() {
      this.$store.dispatch("setCurrPage", this.getCurrPage + 1);
    },
    prevPage() {
      this.$store.dispatch("setCurrPage", this.getCurrPage - 1);
    },
  },
};
</script>

<style scoped>
.pagination-container {
  display: flex;
  justify-content: start;
  align-items: center;
}
.chevron-button {
  background-color: transparent;
  border: none;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  transition: 250ms;
}
.chevron-button:not(:disabled):hover {
  background-color: rgb(238, 238, 238);
}
</style>
