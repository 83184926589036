const LOW_DOCUMENT_COUNT = 50;
const MEDIUM_DOCUMENT_COUNT = 100;
const FLAG_EMOJI = "🚩";
const CHECKMARK_EMOJI = "✅";
const ALL_GOOD_EMOJI = "🥳";

export default {
  methods: {
    fiftyDaysSinceLastLogin(site) {
      if (!site.lastLoginTime) {
        return false;
      }
      const diff =
        (Date.now() - new Date(site.lastLoginTime)) / (1000 * 60 * 60 * 24);
      return diff > 50;
    },
    addFlag(rating, category, count) {
      rating.issuesCount += count;
      rating.emojiString += FLAG_EMOJI.repeat(count);
      rating.breakdown[category].value = FLAG_EMOJI.repeat(count);
    },
    initializeRating() {
      return {
        issuesCount: 0, emojiString: "", breakdown: {
          documentCount: { label: "Document Count", value: CHECKMARK_EMOJI },
          lastLogin: { label: "Days Since Last Login", value: CHECKMARK_EMOJI },
          touchscreenHeartbeat: { label: "Touchscreen Heartbeat", value: CHECKMARK_EMOJI },
          touchscreenFullscreen: { label: "Touchscreen Fullscreen", value: CHECKMARK_EMOJI },
          lastModified: { label: "Days Since Last Modified", value: CHECKMARK_EMOJI },
          installationMedia: { label: "Installation Media", value: CHECKMARK_EMOJI },
        }
      }
    },
    siteRating(site) {
      const { documentCount, lastTouchscreenHeartbeat, csDetails } = site;

      let rating = this.initializeRating();

      if (documentCount < LOW_DOCUMENT_COUNT) {
        this.addFlag(rating, 'documentCount', 2);
      } else if (documentCount < MEDIUM_DOCUMENT_COUNT) {
        this.addFlag(rating, 'documentCount', 1);
      }

      const daysSinceLastLogin = this.daysSince(site.lastLoginTime);
      if (daysSinceLastLogin > 100) {
        this.addFlag(rating, 'lastLogin', 2);
      } else if (!site.lastLoginTime || daysSinceLastLogin > 60) {
        this.addFlag(rating, 'lastLogin', 1);
      }

      const daysSinceLastModified = this.daysSince(site.lastModified);
      if (daysSinceLastModified > 365) {
        this.addFlag(rating, 'lastModified', 5);
      } else if (daysSinceLastModified > 200) {
        this.addFlag(rating, 'lastModified', 3);
      } else if (daysSinceLastModified > 100) {
        this.addFlag(rating, 'lastModified', 2);
      } else if (daysSinceLastModified > 60) {
        this.addFlag(rating, 'lastModified', 1);
      }
      // add flag if no touchscreen heartbeat or if last heartbeat wasn't fullscreen
      if (!lastTouchscreenHeartbeat || lastTouchscreenHeartbeat.timestamp === null) {
        this.addFlag(rating, 'touchscreenHeartbeat', 1);
        delete rating.breakdown.touchscreenFullscreen;
      }
      if (lastTouchscreenHeartbeat && !lastTouchscreenHeartbeat.fullscreen) {
        this.addFlag(rating, 'touchscreenFullscreen', 1);
      }

      if (csDetails.installationMedia.length === 0) {
        this.addFlag(rating, 'installationMedia', 1);
      }


      if (rating.issuesCount === 0) {
        rating.emojiString = ALL_GOOD_EMOJI;
      }
      return rating;
    },
    daysSince(date) {
      if (!date) {
        return 0;
      }
      const diff = (Date.now() - new Date(date)) / (1000 * 60 * 60 * 24);
      return diff;
    },
  },
};